import React, { useState, useEffect } from 'react';
import AguImage from '../img/augustoImage.jpeg'
import AguImageOverlay from '../img/augustoImageOverlay.png'
import '../App.css';
import '@fortawesome/fontawesome-free/css/all.css';

function Navbar({ scrollToSection, setShowMenu, showMenu, openAbout }) {

  const [showNav, setShowNav] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navBackground, setNavBackground] = useState(false);
  // mobile variables
  // const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = (val) => {
    setShowMenu(val || !showMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setShowNav(position <= 0 || scrollPosition > position)
      setScrollPosition(position);
      const show = window.scrollY > 50;
      if (show) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const handleScrollFromMenu = (section) => {
    setShowMenu(false);
    setTimeout(() => {
      scrollToSection(section);
    }, 100); // adjust delay as needed
  };


  return (
    <div className={`top-0 fixed z-30 w-full transition-all duration-500 slideInNav ${showNav ? 'opacity-100' : 'opacity-0'} ${navBackground ? 'bg-back-100 shadow-xl' : ''}`}>
      <div className="flex justify-center items-center">
        <div className="flex justify-between items-center text-center px-6 lg:px-0 py-6 text-xs w-full lg:w-3/5">
          <div onClick={() => scrollToSection('hero')} className="bg-other-100 text-back-100 font-press-start-2p font-bold p-1 hover:rotate-6 transition-transform duration-500 ease-in-out slideInFromTopTwo">
            software engineer
          </div>
          <div className="hidden sm:flex gap-8 text-md">
            <ul className="flex space-x-8 justify-center items-center text-back-100 font-press-start-2p">
              <li className={`text-back-400 hover:text-other-100 slideInFromTop`}>
                <a href="/resume.pdf" target="_blank">
                  resume
                </a>
              </li>
              <li onClick={() => scrollToSection('projects')} className="text-back-400 hover:text-other-100 slideInFromTopTwo">projects</li>
              <li onClick={() => openAbout()} className="text-back-400 hover:text-other-100 slideInFromTopThree">contact</li>
              {/*<li className="flex justify-center items-center text-xl text-back-400 hover:text-other-100 slideInFromTopFour">
                <i class="fa-solid fa-circle-half-stroke"></i>
              </li>*/}
            </ul>
          </div>
          <div className="block sm:hidden">
            <button onClick={handleShowMenu} className="inline-flex items-center justify-center p-2 rounded-md text-back-400 hover:text-other-100">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <span className="sr-only">Open main menu</span>
            </button>
          </div>
          <div
            className={`fixed  flex flex-col items-center justify-center gap-8 p-16 w-full right-0 h-screen sm:sticky top-0 bg-back-400 sm:bg-transparent transition-all ${
              showMenu ? "sm:hidden" : "opacity-0 pointer-events-none sm:opacity-100 sm:pointer-events-auto sm:hidden"
            }`}
          >
            {
              showMenu ?
              <>
                <a href="/resume.pdf" target="_blank" className="flex gap-4 items-center w-full font-press-start-2p text-lg text-back-100 px-4 py-2 drop-shadow-lg border border-white" >
                  <i class="fa-solid fa-diagram-project"></i>
                  <div>resume</div>
                </a>
                <button onClick={() => handleScrollFromMenu('projects')} className="flex gap-4 items-center w-full font-press-start-2p text-lg text-back-100 px-4 py-2 drop-shadow-lg border border-white" >
                  <i class="fa-solid fa-diagram-project"></i>
                  <div>projects</div>
                </button>
                <button onClick={() => openAbout()} className="flex gap-4 items-center w-full font-press-start-2p text-lg text-back-100 px-4 py-2 drop-shadow-lg border border-white hover:bg-gray-100" >
                  <i class="fa-solid fa-diagram-project"></i>
                  <div>contact</div>
                </button>
                <button onClick={handleScrollFromMenu} className="flex gap-4 items-center text-4xl text-back-100"  onClick={() => {setShowMenu(false)}}>
                  <i class="fa-regular fa-circle-xmark hover:text-other-100"></i>
                </button>
              </>
              :
              ""
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

// <div className={`top-0 fixed z-30 w-full transition-all duration-500 slideInNav ${showNav ? 'opacity-100' : 'opacity-0'}`}>
