import React, { useState, useEffect } from 'react';
import '../App.css';
import AguImage from '../img/aguAboutImage.png'
import AguImageOverlay from '../img/augustoImageOverlay.png'
import '@fortawesome/fontawesome-free/css/all.css';

const AboutSection = ({ className, onClose, showAboutPage }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 250); // Set the delay here (4000ms = 4 seconds)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={`${className}`}>
      <div className="grid grid-col md:grid-cols-2 justify-center items-center w-full h-full md:w-[80%] md:h-[90%] shadow-2xl">
         <div className="block md:hidden flex flex-col justify-start items-start text-start bg-back-400 w-full h-full py-12 px-12">
            <div className = "flex flex-rows justify-between w-full text-back-100 text-xl lg:text-2xl 2xl:text-4xl mb-2">
              <div className="flex justify-center items-center font-press-start-2p">
                contact me.
              </div>
              <button onClick={onClose}>
                <i class="fa-regular fa-circle-xmark hover:text-other-100"></i>
              </button>
            </div>
            <a href="mailto:agulimonti09@gmail.com">
              <div className="bg-other-100 text-[10px] 2xl:text-[14px] 3xl:text-[16px] text-back-100 font-press-start-2p font-bold p-1 mb-2 hover:rotate-6 transition-transform duration-500 ease-in-out">
                agulimonti09@gmail.com
              </div>
            </a>
            <div className="text-sm lg:text-md 2xl:text-2xl 3xl:text-4xl text-back-100 font-league-spartan mb-8">
               <div className="mb-4">
                 Step into my world!
               </div>
               <div className="mb-4">
                  Whether you are looking to hire an experienced software engineer, have a question or just want to say hi. Feel free to reach out.
               </div>
               <div className="mb-4">
                  And thank you in advance for your time and consideration!
               </div>
            </div>
            <a href="mailto:agulimonti09@gmail.com" className={`flex justify-center items-center text-center text-sm md:text-lg 2xl:text-2xl text-back-100 font-press-start-2p py-2 px-8 bg-other-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out`}>
               send message
            </a>
         </div>
         <div className={`flex flex-col justify-start items-start text-start bg-back-100 w-full h-full py-12 md:py-24 px-12 ${showAboutPage ? "slideInAboutTop": "slideOutAboutTop"}`}>
            <div className="flex text-xl lg:text-2xl 2xl:text-4xl justify-center items-center font-press-start-2p mb-2 text-back-400">
              about me.
            </div>
            <div className="bg-other-100 text-[10px] 2xl:text-[14px] 3xl:text-[16px] text-back-100 font-press-start-2p font-bold p-1 mb-2 hover:rotate-6 transition-transform duration-500 ease-in-out">
              software engineer
            </div>
            <div className="text-sm lg:text-md 2xl:text-2xl 3xl:text-4xl text-back-400 font-league-spartan">
              <div className="mb-4">
                Nice to meet you! My name is Augusto, and I have a deep passion for crafting efficient and elegant software solutions to meet a variety of needs.
              </div>
              <div className="mb-4">
              I am a serial start-up engineer with experience in bringing products/apps to market and building tech stacks from scratch. To date, I have been fortunate to work in various environments,
              including an <a href="https://thestoryroom.com/" target="_blank" rel="noopener noreferrer" className="text-other-100 hover:underline">advertising agency</a>,
              and as an <a href="https://maxinout.com/" target="_blank" rel="noopener noreferrer" className="text-other-100 hover:underline">entrepreneur</a>.
              </div>
              <div className="mb-4">
              I also recently built <a href="https://www.maxinout.com/bodyshop" target="_blank" rel="noopener noreferrer" className="text-other-100 hover:underline">The Body Shop</a>,
              a B2B SaaS web3 product that simplifies NFT trait shop management and customization platforms.
              </div>
              <div className="mb-4">
                Having gained invaluable experience while running my own business for over two years, I am confident I can make an immediate impact at your company.
                Aside from my technical proficiency, I possess strong skills in creative problem-solving, innovative product strategy, and decisive decision-making.
              </div>
            </div>
            <div className="hidden md:grid grid-cols-4 grid-rows-2 justify-between gap-4 lg:text-2xl xl:text-4xl items-center text-center text-back-400 w-full">
              <div className="flex flex-col justify-center items-center group">
                <i className="fab fa-react group-hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">react</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i className="fab fa-python hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">python</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i className="fas fa-database hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">mysql</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i className="fas fa-cloud hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">aws</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i class="fa-brands fa-js hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">javascript</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i class="fa-brands fa-node-js hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">node.js</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i class="fa-brands fa-html5 hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">html5</p>
              </div>
              <div className="flex flex-col justify-center items-center group">
                <i class="fa-solid fa-pen-nib hover:text-other-100"></i>
                <p className="text-xs font-league-spartan invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-500">ui/ux</p>
              </div>
            </div>
         </div>
         <div className={`hidden md:flex flex-col justify-start items-start text-start bg-back-400 w-full h-full py-24 px-12 ${showAboutPage ? 'slideInAboutTop' : 'slideOutAboutTop'}`}>
           <div className = "flex flex-rows justify-between w-full text-back-100 text-xl lg:text-2xl 2xl:text-4xl mb-2">
             <div className="flex justify-center items-center font-press-start-2p">
               contact me.
             </div>
             <button onClick={onClose}>
               <i class="fa-regular fa-circle-xmark hover:text-other-100"></i>
             </button>
           </div>
           <a href="mailto:agulimonti09@gmail.com">
             <div className="bg-other-100 text-[10px] 2xl:text-[14px] 3xl:text-[16px] text-back-100 font-press-start-2p font-bold p-1 mb-2 hover:rotate-6 transition-transform duration-500 ease-in-out">
               agulimonti09@gmail.com
             </div>
           </a>
           <div className="text-sm lg:text-md 2xl:text-2xl 3xl:text-4xl text-back-100 font-league-spartan mb-8">
              <div className="mb-4">
                Step into my world!
              </div>
              <div className="mb-4">
                 Whether you are looking to hire an experienced software engineer, have a question or just want to say hi. Feel free to reach out.
              </div>
              <div className="mb-4">
                 And thank you in advance for your time and consideration!
              </div>
           </div>
           <a href="mailto:agulimonti09@gmail.com" className={`flex justify-center items-center text-center text-sm md:text-lg 2xl:text-2xl text-back-100 font-press-start-2p py-2 px-8 bg-other-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out`}>
              send message
           </a>
         </div>
      </div>
    </div>
  );
};

export default AboutSection;
