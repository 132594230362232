import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { Document, Page } from 'react-pdf';

const HeroSection = React.forwardRef((props, ref) =>  {
  const initialText = "Augusto Limonti. Engineering digital experiences to drive success.";
  const [nameText, setNameText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [showCaret, setShowCaret] = useState(true);
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2000); // Set the delay here (4000ms = 4 seconds)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setShowNav(position <= 0 || scrollPosition > position)
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  return (
    <div ref={ref} className="flex flex-col justify-center items-center relative h-screen">
      <div className={`bg-back-100 absolute left-0 top-1/2 transform translate-y-[-50%] w-[60px] h-[250px] flex items-center justify-center shadow-2xl z-10 hidden md:block ${isVisible ? 'opacity-100 transition-opacity duration-500 ease-in-out' : 'opacity-0'}`}>
        <div className="grid grid-col-4 w-full h-full p-2">
          <div className="flex justify-center items-center text-back-400 hover:bg-other-100 hover:text-back-100 rounded-xl">
            <a href="https://github.com/augustolimonti" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-github text-2xl"></i>
            </a>
          </div>
          <div className="flex justify-center items-center text-back-400 hover:bg-other-100 hover:text-back-100 rounded-xl">
            <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
              <i class="fa-solid fa-link text-2xl"></i>
            </a>
          </div>
          <div className="flex justify-center items-center text-back-400 hover:bg-other-100 hover:text-back-100 rounded-xl">
            <a href="https://www.linkedin.com/in/augusto-limonti-389679b8/" target="_blank" rel="noopener noreferrer">
              <i class="fa-brands fa-linkedin-in text-2xl"></i>
            </a>
          </div>
          <div className="flex justify-center items-center text-back-400 hover:bg-other-100 hover:text-back-100 rounded-xl">
            <a href="mailto:agulimonti09@gmail.com">
              <i class="fa-solid fa-envelope text-2xl"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="w-[90%] md:w-3/5 items-center">
         <div className={`text-3xl md:text-7xl 3xl:text-9xl font-league-spartan font-bold mb-2 md:mb-4 text-back-400 ${isVisible ? 'slideInFromTop' : 'opacity-0'}`}>
            Augusto Limonti.
         </div>
         <div className={`text-2xl md:text-6xl 3xl:text-8xl font-league-spartan font-bold mb-4 text-back-300 ${isVisible ? 'slideInFromTop' : 'opacity-0'}`}>
            Engineering digital experiences to drive success.
         </div>
         <button  onClick={() => props.openAbout()} className={`flex justify-center items-center text-center text-sm md:text-lg text-back-100 font-press-start-2p py-2 px-8 bg-other-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out ${isVisible ? 'slideIn' : 'opacity-0'}`}>
            about me
         </button>
      </div>
      <div className = {`${isVisible ? 'opacity-100 transition-opacity duration-500 ease-in-out' : 'opacity-0'}`}>
        <p onClick={() => props.scrollToSection('projects')} className="absolute bottom-40 sm:bottom-20 left-1/2 transform -translate-x-1/2 text-back-400 text-opacity-20 font-press-start-2p text-md hover:text-other-100"> works </p>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-[140px] sm:h-[70px] bg-back-400 bg-opacity-20 w-[1px]"></div>
      </div>
    </div>
  );
});

export default HeroSection;
