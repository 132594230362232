import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import '../App.css';
import '@fortawesome/fontawesome-free/css/all.css';

const OtherProjects = React.forwardRef((props, ref) => {

  const [projectOne, setProjectOne] = useState({
    name: 'A sleek image customizer to showcase maxin art.',
    description: 'Built a custom website where users can randomize the traits from NFT collection into different combinations and download their creation to post on twitter.',
    tools: ["React.js", "Tailwind", "Python", "AWS"],
    link: "https://www.maxinout.com/customizer",
    githubLink: "https://github.com/augustolimonti/maxinCustomizer"
  })
  const [projectTwo, setProjectTwo] = useState({
    name: 'A 3D customizer and trait store for Astrals NFTs.',
    description: 'Built a custom website for Astrals that enables their users to mint 3D art, buy 3D traits, and customize their 3D avatars.',
    tools: ["React.js", "Metaplex", "Python", "AWS"],
    link: "https://anima.astralsnft.io/"
  })
  const [projectThree, setProjectThree] = useState({
    name: 'The Goons Market & Trait Swapping Mechanics',
    description: 'Built a trait swapping solution that enables equipping and de-equipping traits to Goon NFTs as well as a point system for acquiring new trait drops.',
    tools: ["React.js", "Metaplex", "Python", "AWS"],
    link: "https://traits.retrogoons.xyz/"
  })
  const [projectFour, setProjectFour] = useState({
    name: 'A B2B SaaS web3 Trait Slot Machine.',
    description: 'Constructed a client-focused trait slot machine interface featuring adjustable odds, dynamic pricing in SOL/SPL tokens, and re-roll capabilities for a gamified NFT-customization experience.',
    tools: ["React.js", "Tailwind", "Python", "AWS"],
    link: "https://www.body-shop.xyz/udderchaos"
  })
  const [projectFive, setProjectFive] = useState({
    name: 'A blockchain-based live comics website.',
    description: 'Built a live comics website with token-gated access through wallet authentication and created the underlying technologies used to host unique stories.',
    tools: ["React.js", "Tailwind", "Python", "AWS"]
  })
  const [projectSix, setProjectSix] = useState({
    name: 'A B2B SaaS meme generator.',
    description: 'Built a meme generator available for all projects to leverage to promote their project and entice community participation in meme culture.',
    tools: ["React.js", "Tailwind", "AWS", "Python"],
    link: "https://www.body-shop.xyz/tacotribe"
  })
  const [projectSeven, setProjectSeven] = useState({
    name: 'Maxin Hub Website',
    description: 'A go-to hub for everything Maxin, equipped with tons of features including customizer, whitepaper, and much more.',
    tools: ["React.js", "Tailwind", "AWS", "Python"],
    link: "https://www.maxinout.com/"
  })
  const [projectEight, setProjectEight] = useState({
    name: 'Fusion & Token Transfer Mechanics for Nimbus',
    description: 'Custom website for Nimbus that enables their users to toggle between 2 separate collections, purchase NFTs through spl tokens, and fuse said NFTs.',
    tools: ["React.js", "Metaplex", "AWS", "Python"],
    link: "https://play.nimbus.gallery/"
  })
  const [projectNine, setProjectNine] = useState({
    name: 'This Awesome Website Portfolio',
    description: 'My own website portfolio to showcase my past experiences and highlight the different projects I have worked on.',
    tools: ["React.js", "Tailwind", "CSS"],
    githubLink: "https://github.com/augustolimonti/websitePortfolio"
  })
  const [projectTen, setProjectTen] = useState({
    name: 'Twitter Sentiment Analysis',
    description: 'Performed real-time sentiment analysis on Twitter data using PySpark and NLTK Vader, with the results stored in BigQuery for further analysis.',
    tools: ["Python", "GCP", "PySpark", "SQL"],
    githubLink: "https://github.com/augustolimonti/Twitter-Sentiment-Analysis---Big-Data"
  })
  const [projectEleven, setProjectEleven] = useState({
    name: 'NBA Prediction Training',
    description: 'Trained several machine learning models that would greatly benefit betting decisions by predicting accurate NBA game outcomes.',
    tools: ["Python", "Pandas", "Keras", "SKLearn"],
    githubLink: "https://github.com/augustolimonti/NBA-Game-Predictor---Data-Mining"
  })
  // const [projectTwelve, setProjectTwelve] = useState({
  //   name: 'Dog Breeds Classifier',
  //   description: 'Trained several machine learning models that would greatly benefit betting decisions by predicting accurate NBA game outcomes.',
  //   tools: ["Python", "Pandas", "Keras", "SKLearn"],
  //   githubLink: "https://github.com/augustolimonti/NBA-Game-Predictor---Data-Mining"
  // })

  const [allProjects, setAllProjects] = useState([
    projectOne,
    projectTwo,
    projectThree,
    projectFour,
    projectFive,
    projectSix,
    projectSeven,
    projectEight,
    projectNine,
    projectTen,
    projectEleven
  ])

  const [visible, setVisible] = useState(6); // Number of projects to be shown initially

  // Load more button function
  const loadMore = () => {
    setVisible((prevValue) => prevValue + 6);
  };

  // Show less button function
  const showLess = () => {
    setVisible(6);
  };

  return (
      <div ref={ref} className="flex justify-center items-center relative h-full xl:mb-0">
        <div className="flex flex-col w-[90%] md:w-3/5 items-center z-10 py-12 gap-12">
          <div className="flex flex-rows justify-center items-center">
            <div className="flex justify-center items-center text-center text-sm md:text-lg text-back-100 font-press-start-2p py-2 px-8 bg-other-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out">
              other projects
            </div>
          </div>
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
            {
                allProjects.slice(0, visible).map((project) => {
                  return (
                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                      <div className="flex flex-col items-center justify-center w-full h-full bg-back-400 p-6 lg:p-8 cursor-pointer transition ease-in-out hover:-translate-y-2">
                        <div class="flex w-full min-h-[5vh] justify-between items-start text-back-100 text-2xl 3xl:text-4xl">
                          <i class="fa-solid fa-diagram-project text-back-100"></i>
                          <div className="flex gap-2">
                            {
                              project.githubLink ?
                              <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
                                <i class="fab fa-github"></i>
                              </a>
                              :
                              ""
                            }
                            {
                              project.link ?
                              <a href={project.link} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
                                <i class="fa-solid fa-link"></i>
                              </a>
                              :
                              ""
                            }
                          </div>
                        </div>
                        <div className="flex items-start text-md lg:text-lg 3xl:text-2xl text-start text-back-100 font-league-spartan font-bold w-full min-h-[7vh]" style={{ lineHeight: '1.2' }}>
                          {project.name}
                        </div>
                        <div className="flex items-start text-sm lg:text-md 3xl:text-xl text-start text-back-100 font-league-spartan w-full min-h-[12vh]">
                          {project.description}
                        </div>
                        <div className="flex grid grid-cols-4 gap-1 items-end justify-center w-full min-h-[5vh]">
                          {
                          project.tools.map((tool) => {
                            return (
                              <div className="bg-back-400 w-full font-league-spartan h-[75%] bg-opacity-50 rounded-lg flex items-center justify-center text-center text-back-100 text-[8px] lg:text-[50%] 3xl:text-[100%] px-1">
                              {tool}
                              </div>
                            )
                          })
                        }
                        </div>
                      </div>
                    </a>
                  )
                })
            }
          </div>
          <div className="flex flex-rows gap-4 justify-center items-center mb-8">
            <div className="w-full" />
            <button onClick={visible < allProjects.length ? loadMore : showLess} className="flex justify-center items-center text-center text-sm md:text-lg text-back-400 font-press-start-2p border-2 border-back-400 py-2 px-8  min-w-[150px] md:min-w-[250px] 3xl:min-w-[300px] bg-back-100 hover:bg-back-400 hover:text-back-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out">
              {visible < allProjects.length ? 'show more' : 'show less'}
            </button>
            <div className="w-full" />
          </div>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-full bg-back-400 bg-opacity-20 w-[1px]"></div>
      </div>
  );
});

export default OtherProjects;
