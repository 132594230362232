import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import '../App.css';
import AguImage from '../img/augustoImage.jpeg'
import AguImageOverlay from '../img/augustoImageOverlay.png'
import BodyShopIMG from '../img/BodyShopOverlay.png'
import BodyShopIMGTwo from '../img/BodyShop.png'
import BodyDashIMG from '../img/BodyDashOverlay.png'
import BodyDashIMGTwo from '../img/BodyDash.png'
import BodyDashIMGThree from '../img/BodyDashThree.png'
import RaffleShopIMG from '../img/raffleShopOverlay.png'
import RaffleShopIMGTwo from '../img/raffleShop.png'
import PointsPortalOne from '../img/pointsPortalOverlay.png'
import PointsPortalTwo from '../img/pointsPortalOne.png'
import '@fortawesome/fontawesome-free/css/all.css';
import Carousel, { consts } from 'react-elastic-carousel';
import ProjectItems from './ProjectItems'

const ProjectSection = React.forwardRef((props, ref) => {

  // Info Project 1
  const [bodyShopTools, setBodyShopTools] = useState([
    'react', 'mysql', 'metaplex',
    'aws', 'python'
  ])
  const [bodyShopDescription, setBodyShopDescription] = useState("A B2B SaaS web3 product that offers a no-code solution for any NFT project on Solana to create and manage their own trait shop and customization platform to enable NFT trait swapping, fusions, and mutations through metadata upgrades.")
  const [bodyShopLink, setBodyShopLink] = useState('https://www.maxinout.com/bodyshop')
  const [bodyShopGit, setBodyShopGit] = useState('https://github.com/augustolimonti/BodyShopSample')
  const [bodyShopImages, setBodyShopImages] = useState([BodyShopIMG, BodyShopIMGTwo])
  const [projectOneDetails, setProjectOneDetails] = useState({
      name: "The Body Shop",
      tools: bodyShopTools,
      description: bodyShopDescription,
      link: bodyShopLink,
      gitLink: bodyShopGit,
      images: bodyShopImages
  })

  // Info Project 1
  const [bodyShopDashTools, setbodyShopDashTools] = useState([
    'react', 'mysql', 'metaplex', 'aws', 'python'
  ])
  const [bodyShopDashDescription, setbodyShopDashDescription] = useState("The Body Shop comes equipped with a state of the art Dashboard where project owners can manage their own trait shop and customization platform for their NFT collection without any previous coding knowledge.")
  const [bodyShopDashLink, setbodyShopDashLink] = useState('https://bodyshop-dash.xyz/')
  const [bodyShopDashGit, setbodyShopDashGit] = useState('https://github.com/augustolimonti/BodyShopSample')
  const [bodyShopDashImages, setbodyShopDashImages] = useState([BodyDashIMG, BodyDashIMGTwo])
  const [projectTwoDetails, setProjectTwoDetails] = useState({
      name: "The Body Dash",
      tools: bodyShopDashTools,
      description: bodyShopDashDescription,
      link: bodyShopDashLink,
      gitLink: bodyShopDashGit,
      images: bodyShopDashImages
  })

  // Info Project 3
  const [raffleShopTools, setRaffleShopTools] = useState([
    'react', 'node.js', 'ethers.js', 'aws', 'python'
  ])
  const [raffleShopDescription, setRaffleShopDescription] = useState("A peer-to-peer protocol that allows users to host and join raffles for NFTs (both ERC721 and ERC1155 tokens) on the Polygon Blockchain.")
  const [raffleShopLink, setRaffleShopLink] = useState('https://www.maxinout.com/raffles')
  const [raffleShopGit, setRaffleShopGit] = useState('https://github.com/augustolimonti/RaffleShopSample')
  const [raffleShopImages, setRaffleShopImages] = useState([RaffleShopIMG, RaffleShopIMGTwo])
  const [projectThreeDetails, setProjectThreeDetails] = useState({
      name: "Raffle Store",
      tools: raffleShopTools,
      description: raffleShopDescription,
      link: raffleShopLink,
      gitLink: raffleShopGit,
      images: raffleShopImages
  })

  // Info Project 4
  const [pointsPortalTools, setPointsPortalTools] = useState([
    'react', 'node.js', 'ethers.js', 'aws', 'python'
  ])
  const [pointsPortalDescription, setPointsPortalDescription] = useState("Developed a points system where users earn points through Discord roles and engaging with tweets, and a front-end portal to check points, leaderboards, and claim ERC1155/ERC721 tokens based on point accumulation.")
  const [pointsPortalLink, setPointsPortalLink] = useState('https://www.maxinout.com/points/profile')
  const [pointsPortalGit, setPointsPortalGit] = useState('https://www.maxinout.com/points/profile')
  const [pointsPortalImages, setPointsPortalImages] = useState([PointsPortalOne, PointsPortalTwo])
  const [projectFourDetails, setProjectFourDetails] = useState({
      name: "Points Portal",
      tools: pointsPortalTools,
      description: pointsPortalDescription,
      link: pointsPortalLink,
      gitLink: pointsPortalGit,
      images: pointsPortalImages
  })

  // allProjectsArray
  const [allProjects, setAllProjects] = useState([projectOneDetails, projectTwoDetails, projectThreeDetails, projectFourDetails])

  return (
    <div ref={ref} className="flex flex-col justify-start items-center relative h-full min-h-screen">
       <div className="flex flex-col w-[90%] md:w-3/5 items-center z-10 py-12 gap-12 sm:gap-24 2xl:gap-48">
         <div className="flex flex-rows justify-center items-center">
           <div className="flex justify-center items-center text-center text-sm md:text-lg text-back-100 font-press-start-2p py-2 px-8 bg-other-100 hover:origin-center hover:rotate-6 transition-transform duration-500 ease-in-out">
             featured projects
           </div>
         </div>
         <div className = "flex flex-col items-center justify-center text-center gap-12 sm:gap-24 2xl:gap-48">
             {allProjects.map((project, index) => (
               <ProjectItems
                 index={index}
                 name={project.name}
                 features={project.features}
                 tools={project.tools}
                 description={project.description}
                 link={project.link}
                 gitLink = {project.gitLink}
                 images = {project.images}
               />
             ))}
         </div>
       </div>
       <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-full bg-back-400 bg-opacity-20 w-[1px]"></div>
    </div>
  );
});

export default ProjectSection;
